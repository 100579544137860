exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-2-js": () => import("./../../../src/pages/about-us2.js" /* webpackChunkName: "component---src-pages-about-us-2-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-banking-savings-js": () => import("./../../../src/pages/banking/savings.js" /* webpackChunkName: "component---src-pages-banking-savings-js" */),
  "component---src-pages-blog-[slug]-js": () => import("./../../../src/pages/blog/[slug].js" /* webpackChunkName: "component---src-pages-blog-[slug]-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-business-agents-js": () => import("./../../../src/pages/business/agents.js" /* webpackChunkName: "component---src-pages-business-agents-js" */),
  "component---src-pages-business-large-js": () => import("./../../../src/pages/business/large.js" /* webpackChunkName: "component---src-pages-business-large-js" */),
  "component---src-pages-business-small-js": () => import("./../../../src/pages/business/small.js" /* webpackChunkName: "component---src-pages-business-small-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-roles-[slug]-js": () => import("./../../../src/pages/careers/roles/[slug].js" /* webpackChunkName: "component---src-pages-careers-roles-[slug]-js" */),
  "component---src-pages-careers-roles-index-js": () => import("./../../../src/pages/careers/roles/index.js" /* webpackChunkName: "component---src-pages-careers-roles-index-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-credit-terms-of-service-js": () => import("./../../../src/pages/credit-terms-of-service.js" /* webpackChunkName: "component---src-pages-credit-terms-of-service-js" */),
  "component---src-pages-developers-js": () => import("./../../../src/pages/developers.js" /* webpackChunkName: "component---src-pages-developers-js" */),
  "component---src-pages-drc-about-us-js": () => import("./../../../src/pages/drc/about-us.js" /* webpackChunkName: "component---src-pages-drc-about-us-js" */),
  "component---src-pages-drc-index-js": () => import("./../../../src/pages/drc/index.js" /* webpackChunkName: "component---src-pages-drc-index-js" */),
  "component---src-pages-drc-terms-of-service-js": () => import("./../../../src/pages/drc/terms-of-service.js" /* webpackChunkName: "component---src-pages-drc-terms-of-service-js" */),
  "component---src-pages-driverqr-js": () => import("./../../../src/pages/driverqr.js" /* webpackChunkName: "component---src-pages-driverqr-js" */),
  "component---src-pages-home-2-js": () => import("./../../../src/pages/home2.js" /* webpackChunkName: "component---src-pages-home-2-js" */),
  "component---src-pages-home-3-js": () => import("./../../../src/pages/home3.js" /* webpackChunkName: "component---src-pages-home-3-js" */),
  "component---src-pages-ims-js": () => import("./../../../src/pages/ims.js" /* webpackChunkName: "component---src-pages-ims-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invoice-js": () => import("./../../../src/pages/invoice.js" /* webpackChunkName: "component---src-pages-invoice-js" */),
  "component---src-pages-max-js": () => import("./../../../src/pages/max.js" /* webpackChunkName: "component---src-pages-max-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-mini-1-js": () => import("./../../../src/pages/mini/1.js" /* webpackChunkName: "component---src-pages-mini-1-js" */),
  "component---src-pages-mini-2-js": () => import("./../../../src/pages/mini/2.js" /* webpackChunkName: "component---src-pages-mini-2-js" */),
  "component---src-pages-mini-3-js": () => import("./../../../src/pages/mini/3.js" /* webpackChunkName: "component---src-pages-mini-3-js" */),
  "component---src-pages-mini-4-js": () => import("./../../../src/pages/mini/4.js" /* webpackChunkName: "component---src-pages-mini-4-js" */),
  "component---src-pages-mini-5-js": () => import("./../../../src/pages/mini/5.js" /* webpackChunkName: "component---src-pages-mini-5-js" */),
  "component---src-pages-mini-6-js": () => import("./../../../src/pages/mini/6.js" /* webpackChunkName: "component---src-pages-mini-6-js" */),
  "component---src-pages-mini-7-js": () => import("./../../../src/pages/mini/7.js" /* webpackChunkName: "component---src-pages-mini-7-js" */),
  "component---src-pages-mini-8-js": () => import("./../../../src/pages/mini/8.js" /* webpackChunkName: "component---src-pages-mini-8-js" */),
  "component---src-pages-mini-js": () => import("./../../../src/pages/mini.js" /* webpackChunkName: "component---src-pages-mini-js" */),
  "component---src-pages-partners-terms-and-conditions-js": () => import("./../../../src/pages/partners-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-partners-terms-and-conditions-js" */),
  "component---src-pages-pos-terms-of-service-js": () => import("./../../../src/pages/pos-terms-of-service.js" /* webpackChunkName: "component---src-pages-pos-terms-of-service-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-accept-payments-js": () => import("./../../../src/pages/products/accept-payments.js" /* webpackChunkName: "component---src-pages-products-accept-payments-js" */),
  "component---src-pages-products-checkout-js": () => import("./../../../src/pages/products/checkout.js" /* webpackChunkName: "component---src-pages-products-checkout-js" */),
  "component---src-pages-products-make-payments-js": () => import("./../../../src/pages/products/make-payments.js" /* webpackChunkName: "component---src-pages-products-make-payments-js" */),
  "component---src-pages-products-nomba-pro-js": () => import("./../../../src/pages/products/nomba-pro.js" /* webpackChunkName: "component---src-pages-products-nomba-pro-js" */),
  "component---src-pages-products-pos-terminals-js": () => import("./../../../src/pages/products/pos-terminals.js" /* webpackChunkName: "component---src-pages-products-pos-terminals-js" */),
  "component---src-pages-qr-2-js": () => import("./../../../src/pages/QR2.js" /* webpackChunkName: "component---src-pages-qr-2-js" */),
  "component---src-pages-qr-js": () => import("./../../../src/pages/qr.js" /* webpackChunkName: "component---src-pages-qr-js" */),
  "component---src-pages-savings-terms-of-service-js": () => import("./../../../src/pages/savings-terms-of-service.js" /* webpackChunkName: "component---src-pages-savings-terms-of-service-js" */),
  "component---src-pages-terminal-guide-js": () => import("./../../../src/pages/terminal-guide.js" /* webpackChunkName: "component---src-pages-terminal-guide-js" */),
  "component---src-pages-terminals-js": () => import("./../../../src/pages/terminals.js" /* webpackChunkName: "component---src-pages-terminals-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

